export const data_2024 = [
  {
    name: "Mandatory Public Disclosure.",
    date: "22-06-2024",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2024/1.-Mandatory-Public-Disclosure..pdf",
    pageLink: "/annexure/2024/mandatory-public-disclosure",
  },
  {
    name: "Annexure - 1 (a) - Copies od Affiliation - Upgradation letter and recent extension of Affiliation, If any",
    date: "22-06-2024",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2024/2.-Annexure---1-(a)---Copies-od-Affiliation---Upgradation-letter-and-recent-extension-of-Affiliation,-If-any.pdf",
    pageLink: "/annexure/2024/annexure-1a",
  },
  {
    name: "Annexure - 1 (b) - Copies od Affiliation - Upgradation letter and recent extension of Affiliation, If any",
    date: "22-06-2024",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2024/3.-Annexure---1-(b)---Copies-od-Affiliation---Upgradation-letter-and-recent-extension-of-Affiliation,-If-any.pdf",
    pageLink: "/annexure/2024/annexure-1b",
  },
  {
    name: "Annexure - 2 - Copies of Societies - Trust company registration - renewal certificate as applicable",
    date: "22-06-2024",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2024/4.-Annexure---2---Copies-of-Societies---Trust-company-registration---renewal-certificate-as-applicable.pdf",
    pageLink: "/annexure/2024/annexure-2",
  },
  {
    name: "Annexure - 3 & 4 - Copy of NO objection certification (NOC) issued.",
    date: "22-06-2024",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2024/5.-Annexure---3-&-4---Copy-of-NO-objection-certification-(NOC)-issued..pdf",
    pageLink: "/annexure/2024/annexure-3-4",
  },
  {
    name: "Annexure - 5 - Copy of Valid Building Safety Certificate as per the National Building Code.",
    date: "22-06-2024",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2024/6.-Annexure---5---Copy-of-Valid-Building-Safety-Certificate-as-per-the-National-Building-Code..pdf",
    pageLink: "/annexure/2024/annexure-5",
  },
  {
    name: "Annexure - 6 - Copy of Valid Fire Safety Certificate Issued by the Competent Authority.",
    date: "22-06-2024",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2024/7.-Annexure---6---Copy-of-Valid-Fire-Safety-Certificate-Issued-by-the-Competent-Authority..pdf",
    pageLink: "/annexure/2024/annexure-6",
  },
  {
    name: "Annexure - 7 - Copy of the Deo Certificate submitted by the School for Affiliation-Upgradation- Extension of Affiliation or self Certification by School.",
    date: "22-06-2024",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2024/8.-Annexure---7---Copy-of-the-Deo-Certificate-submitted-by-the-School-for-Affiliation-Upgradation--Extension-of-Affiliation-or-self-Certification-by-School..pdf",
    pageLink: "/annexure/2024/annexure-7",
  },
  {
    name: "Annexure - 8 - Copies of valid Water Health and Sanitation Certificates.",
    date: "22-06-2024",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2024/9.-Annexure---8---Copies-of-valid-Water-Health-and-Sanitation-Certificates..pdf",
    pageLink: "/annexure/2024/annexure-8",
  },
  {
    name: "Annexure - 9 - Monthly Fee Structure of The School (2024-2025)",
    date: "22-06-2024",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2024/10.-Annexure---9---Monthly-Fee-Structure-of-The-School-(2024-2025).pdf",
    pageLink: "/annexure/2024/annexure-9",
  },
  {
    name: "Annexure - 10 - Annual Academic Calendar (2024-2025)",
    date: "22-06-2024",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2024/11.-Annexure---10---Annual-Academic-Calendar-(2024-2025).pdf",
    pageLink: "/annexure/2024/annexure-10",
  },
  {
    name: "Annexure - 11 - List of School Managing Committee Members-2024 (SMC)",
    date: "22-06-2024",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2024/12.-Annexure---11---List-of-School-Managing-Committee-Members-2024-(SMC).pdf",
    pageLink: "/annexure/2024/annexure-11",
  },
  {
    name: "Annexure - 12 - List of Parents Teacher Association (PTA) Members-2024",
    date: "22-06-2024",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2024/13.-Annexure---12---List-of-Parents-Teacher-Association-(PTA)-Members-2024.pdf",
    pageLink: "/annexure/2024/annexure-12",
  },
  {
    name: "Annexure - 13 - List Three Year Result of The Board Examination as per Applicability.",
    date: "22-06-2024",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2024/14.-Annexure---13---List-Three-Year-Result-of-The-Board-Examination-as-per-Applicability..pdf",
    pageLink: "/annexure/2024/annexure-13",
  },
];
