import React from "react";
import Layout from "../Layout";
import "./style.scss";

export default function Annexure({ title, pdfUrl }) {
  return (
    <Layout>
      <div className="pdfview">
        <h1 className="heading">{title}</h1>

        <div
          type="application/pdf"
          width="100%"
          height="100%"
          className="pdfview__container"
        >
          <object
            type="application/pdf"
            width="100%"
            height="100%"
            className=""
            data={pdfUrl}
          ></object>
        </div>
      </div>
    </Layout>
  );
}
